<template>
  <div class="employee-list__state">
    <div class="text--secondary">
      {{ $t("auth.teamMode.employeeList.loadingText") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "EmployeeListLoadingState",
};
</script>
