<template>
  <div class="employee-list__state">
    <v-icon size="100" class="mb-2">$users</v-icon>
    <div class="text--secondary">
      {{ $t("auth.teamMode.employeeList.emptyText") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "EmployeeListEmptyState",
};
</script>
