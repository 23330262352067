import { computed } from "vue";

export const makeSearchProps = () => ({
  employees: {
    type: Array,
    default: () => [],
  },
  query: {
    type: String,
    default: "",
  },
  minQueryLength: {
    type: Number,
    default: 2,
  },
  shifts: {
    type: Array,
    default: () => [],
  },
});

/**
 * @typedef {Object} Employee
 * @property {String} firstName
 * @property {String} lastName
 * @property {String} [drsEmployeeCode]
 * @property {String} [email]
 * @property {String} [phoneNumber]
 * @property {String} [lastActionAt]
 *
 * @typedef {Object} EmployeeSearchProps
 * @property {Employee[]} employees
 * @property {String} [query]
 * @property {Number} [minQueryLength]
 */

/**
 * @param {EmployeeSearchProps} props
 */
export function useSearch(props) {
  const searchedEmployees = computed(() => {
    let employees = props.employees.slice();

    if (props.query?.length >= props.minQueryLength) {
      employees = search(employees, props.query);
    }

    return employees;
  });

  const isEmpty = computed(() => searchedEmployees.value.length === 0);

  function search(employees, query) {
    const regex = new RegExp(`.*${query}.*`, "i");
    return employees.filter(
      ({ firstName, lastName, email, drsEmployeeCode, phoneNumber }) =>
        regex.test(firstName) ||
        regex.test(lastName) ||
        regex.test(email) ||
        regex.test(drsEmployeeCode) ||
        regex.test(phoneNumber)
    );
  }

  return { search, searchedEmployees, isEmpty };
}
