<template>
  <v-list class="employee-list" v-bind="$attrs">
    <v-fade-transition hide-on-leave>
      <LoadingState v-if="loading" />
      <EmptyState v-else-if="isEmpty" />
    </v-fade-transition>
    <EmployeeListItem
      v-for="employee in computedEmployees"
      :key="employee.id"
      :employee="employee"
      hide-status
      @click="$emit('click:employee', employee)"
    />
  </v-list>
</template>

<script>
import { computed, reactive } from "vue";
import orderBy from "lodash/orderBy";
import { makeSearchProps, useSearch } from "@/composables/employee/search";

import EmptyState from "./_EmptyState.vue";
import LoadingState from "./_LoadingState.vue";
import EmployeeListItem from "../EmployeeListItem.vue";

export default {
  name: "EmployeeBaseList",
  components: { EmployeeListItem, EmptyState, LoadingState },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    ...makeSearchProps(),
  },
  setup(props) {
    const { searchedEmployees, isEmpty } = useSearch(props);

    const scheduledEmployees = computed(() => {
      return searchedEmployees.value.filter((emp) => {
        return props.shifts.find((shift) => shift.employee.id === emp.id);
      });
    });

    const notScheduledEmployees = computed(() => {
      return searchedEmployees.value.filter((emp) => {
        return !props.shifts.find((shift) => shift.employee.id === emp.id);
      });
    });

    function orderEmployees(employees) {
      return orderBy(employees, [(x) => x.lastActionAt ?? ""], ["desc"]);
    }

    const computedEmployees = computed(() => {
      if (props.shifts.length > 0) {
        return orderEmployees(scheduledEmployees.value).concat(
          orderEmployees(notScheduledEmployees.value)
        );
      } else {
        return orderEmployees(searchedEmployees.value);
      }
    });

    return {
      computedEmployees,
      isEmpty,
      scheduledEmployees,
      notScheduledEmployees,
    };
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
